import React from 'react';

const Home = () => {
    return (

        <div className="page">
            <section>
                <h1>Hello, I'm Juuso.</h1>
                <h2>I’m the <u>silent salesman</u> for your business.</h2>
                <p>Design is not about the user, it’s about the client. Design has been always about selling things, silently. And that’s what I do as a <b>silent salesman</b>.<br /><br />
                    I'm an experienced lead UX/UI/visual designer. I design world class <b>mobile apps, web services, websites and other digital products</b>. I specialise in product/service concepts, UX/UI design, visual design, design leadership, front end development, animation and branding.<br /><br />
                    Rather than offering a new pair of hands for making things pretty, I approach my projects with an outside vision and world-class-design that’ll improve your business. I can bridge that crucial gap between your business objectives and a user’s wants & needs.
                    My goal is to help businesses get to the next level with digital products that are built on trust with interactions that feel magical. <b>I design quality digital products that will sell themselves to their users</b>.</p>
            </section>
        </div>
    )
}

export default Home;