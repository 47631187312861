import React from 'react';

const Comments = () => {
    return(
        <div className="page">
            <section>
                <h1>Comments</h1>
                <p>Here are some comments from my clients and colleagues.</p>
                <hr></hr>
                <h4>Vincit</h4>
                <p>Juuso Saastamoinen has worked with Vincit Development Oy as UX / visual / service designer. Juuso is able to take routinely and quickly different situations and offer different views to solve them. In his thoughts, Juuso goes beyond the traditional UX design and also cares about the commercial arguments for the assignments. Juuso's communication is clear regardless of the listener. The quality of the design work has been praised both by the client and the software developers. Juuso communicates gladly with developers to keep the technical implementation and desired user interface in balance.</p>
                <hr></hr>
                <h4>Pekka Jääskeläinen, Design Lead at Telia</h4>
                <p>I directed Juuso Saastamoinen's design work for over a year at Accenture's Creative Services team where he worked as a freelance designer for various projects. Juuso is a passionate and professional designer whose strengths are in digital services. His hands create modern user interfaces that serve the end user as required. Juuso is a dependable and self-employed entrepreneur. He is constantly trying to develop himself and help his colleagues. I recommend Juuso's expertise in design tasks for digital services.</p>
                <hr></hr>
                <h4>Lauri Heino, Developer at Reaktor</h4>
                <p>I have done cooperation with Juuso in a number of mobile and web projects, most of which he has taken the main responsibility for UX-, UI- and visual design. Together we've produced, for example, iOS applications, web systems, and conceptual plans. Juuso is a designer with a strong view. Juuso has a world-class view, and he follows the latest trends in the industry with a close eye. Juuso is a proactive designer and a great communicator. It's nice to work with Juuso, and I warmly recommend his service!</p>
            </section>
        </div>
    )
}

export default Comments;