import React from 'react';
import { Link } from "react-router-dom";
import zapp_1 from './assets/zapp_1.png';
import zapp_2 from './assets/zapp_2.png';
import moovy_1 from './assets/moovy_1.png';
import moovy_2 from './assets/moovy_2.png';
import technopolis_1 from './assets/technopolis_1.png';
import technopolis_2 from './assets/technopolis_2.png';
import eyeye_1 from './assets/eyeye_1.png';
import eyeye_2 from './assets/eyeye_2.png';
import mobile_1 from './assets/mobile_1.png';
import mobile_2 from './assets/mobile_2.gif';

const products = [
    {
        id: '1', 
        label: 'Zapp',
        descriptionMain: 'Zapp guides EV drivers to their destination without a hassle. The right stops at the right time at the right places. Zapp electrifies your journey.',
        imageMain: [zapp_1],
        imageSecondary: [zapp_2]
    },
    {
        id: '2',
        label: 'Moovy',
        descriptionMain: 'Moovy was one of the finalists of UX/CX-category at the Blue Arrow awards 2019. It is a parking service and marketplace that brings together motorists and car park providers. It opens the booms to a new freedom of movement, where the same service works in different parking environments in halls, streets and other parking areas.',
        imageMain: [moovy_1],
        imageSecondary: [moovy_2]
    },
    {
        id: '3',
        label: 'Technopolis',
        descriptionMain: 'Technopolis UMA Workspace app is your digital access to UMA Workspace. With UMA Workspace app you are able to: Purchase a membership, book meeting rooms & caterings, get 24/7 access to your preferred workspace and open the doors with the app, invite your team members and stay connected with them.', 
        imageMain: [technopolis_1],
        imageSecondary: [technopolis_2]
    },
    {
        id: '4',
        label: 'Eyeye TV',
        descriptionMain: 'Wherever you are, start sharing your live moments with the world.', 
        imageMain: [eyeye_1],
        descriptionSecondary: 'A start up company approached me in need of an experienced UX/UI designer. Together with a remote team of developers, the goal was to design a global livestreaming app. Because of the broad audience the user experience needed to be simple and easy to use. After creating wireframes and flowcharts I decided to design the actual user interface with minimal and fun visuals. All in all the UX/UI was designed and implemented excellently with a remote team of developers.',
        imageSecondary: [eyeye_2]
    },
    {
        id: '5',
        label: 'Mobile Payment',
        descriptionMain: 'A concept design for a payment service.', 
        imageMain: [mobile_1],
        imageSecondary: [mobile_2]
    }
  ];

export const Work = () => {
    return(
        <div className="page">
            <section>
                <h1>Work</h1>
                <p>Here are some of my selected projects.</p>
                <div className="worklist">
                { products.map(({id, label}) =>
                    <div className="worklink"><Link to={`/product/${id}`}>{label}<br/></Link></div>) }
                </div>
            </section>
        </div>
    )
}

export const ProductView = ({ match }) => {
    const product = products.find(item => item.id === match.params.id);
    console.log(products, match.params.id)
    return (
      <div className="page">
        <section>
        <Link className="backbutton" to={`/work`}>←</Link>
            <h1>{ product.label }</h1>
            <p>{ product.descriptionMain }</p>
            <img src={ product.imageMain } className="workimg" alt="workimg" />
            <p>{ product.descriptionSecondary }</p>
            <img src={ product.imageSecondary } className="workimg" alt="workimg" />
            <Link to={`/work`}>Back to Projects</Link>
        </section>
      </div>
    )
  }