import React from 'react';

const Contact = () => {
    return (
        <div className="page">
            <section>
                <h1>Contact</h1>
                <p>Interested? Feel free to drop a message to me: <a href="mailto:juuso@luovain.com" title="Inquiry">juuso@luovain.com</a></p>
                <h4 class="title-contact">Juuso Saastamoinen</h4>
                <p class="text-contact">Lead UX/UI/Visual Designer<br />
                    juuso@luovain.com<br />
                    +358 50 326 5691</p>
            </section>
        </div>
    )
}

export default Contact;