import React, { Component } from 'react';
import {
  Route,
  NavLink,
  Switch
} from "react-router-dom";
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import { Helmet } from 'react-helmet';

import './App.css';
import Home from './Home';
import {Work, ProductView} from './Work';
import Comments from './Comments';
import Contact from './Contact';

// class component
class App extends Component {
  render() {
    return (
      <div className="App">
        <Helmet>
          <title>Experienced Lead UX/UI/Visual Designer from Finland | Juuso Saastamoinen</title>
          <meta name="description" content="Experienced Lead UX/UI/Visual Designer from Finland | Juuso Saastamoinen" />
        </Helmet>
        <div className="nav">
          <NavLink exact to="/" activeClassName="active">Home</NavLink>
          <NavLink to="/work" activeClassName="active">Work</NavLink>
          <NavLink to="/comments" activeClassName="active">Comments</NavLink>
          <NavLink to="/contact" activeClassName="active">Contact</NavLink>
        </div>
        <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition
                    key={location.key}
                    timeout={500}
                    classNames="fade"
            >
            <Switch location={location}>
              <Route exact path="/" component={Home} />
              <Route path="/work" component={Work} />
              <Route path="/product/:id" component={ProductView} />
              <Route path="/comments" component={Comments} />
              <Route path="/contact" component={Contact} />
            </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
      </div>
    );
  }
}

export default App;
